// original
// sass-lint:disable no-color-literals no-important

html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  font-size: 10pt;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f0efeb;
  min-width: 320px;
  min-height: 320px;
}

section {
  & + section {
    margin-top: 2em;
  }
}

input,
button,
select,
textarea {
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
}

select,
option,
textarea {
  max-width: 100%;
}

input {
  &[type='text'] {
    max-width: 100%;
  }
}

small {
  font-size: .8em;

  .help-block {
    color: #737373 !important;
  }
}

.alert {
  ul {
    margin-bottom: 0;
  }
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
}

h2 {
  font-size: 1.6em;
  font-weight: normal;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
}

h3 {
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: normal;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
}

h4 {
  font-size: 1em;
  line-height: normal;
  font-weight: bold;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  margin-bottom: 5px;
}

h5 {
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
}

a {
  text-decoration: none;

  &:link,
  &:hover,
  &:active,
  &:target,
  &:visited {
    text-decoration: none;
  }

  .fancybox {
    &> img {
      margin-bottom: .25em;
    }
  }
}

cite {
  font-weight: bold;
}

#wrapper {
  min-height: 100%;
  position: relative;
}

#main {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-bottom: 47px;
}

header {
  padding: 1em;
}

.inactive {
  color: #aaa;

  &:hover {
    color: #888;
  }
}

.hidden {
  display: none;
}

// honeypot field
.salute {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

#header {
  background-color: #004580;
  height: 54px;
  color: #5abcff;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #ffde00;

  a {
    color: #5abcff;

    &:hover,
    &.active {
      color: #fff;
    }
  }

  i {
    font-size: 16px;
  }

  .icon-egu-logo {
    color: #ffde00;
  }
}

#menu {
  line-height: 52px;
  width: 52px;
  text-align: center;
  float: right;
  height: 52px;

  a {
    line-height: 52px;
    width: 52px;
    text-align: center;
    display: inline-block;
    height: 52px;
    cursor: pointer;
  }

  i {
    margin-bottom: 2px;
    font-size: 22px;
    vertical-align: middle;
  }
}

#collapse {
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  font-size: 14px;
  background-color: #fff;
  z-index: 1;

  .nav {
    margin: 0;
    width: 49%;
    display: inline-block;
    overflow: hidden;
    padding: .5em 0 0;

    li {
      padding: 0 .5em;
    }

    & > .active {
      & > a {
        background-color: #e5e5e5;
        box-shadow: 0 3px 8px rgba(0, 0, 0, .125) inset;
        color: #555;
        text-decoration: none;

        &:hover,
        &:focus {
          background-color: #e5e5e5;
          box-shadow: 0 3px 8px rgba(0, 0, 0, .125) inset;
          color: #555;
          text-decoration: none;
        }
      }
    }
  }

  p {
    margin: 0;
    padding: 1em 0;
    color: #777;
    font-weight: normal;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    border-bottom: 2px solid #ffde00;

    i {
      padding: 0 6px;
      color: #777;
      font-size: 22px;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
}


#logo {
  float: left;
  line-height: 52px;
  margin: 0 1em;
}

#nav {
  float: left;
  padding-left: 2em;

  li {
    line-height: 52px;
    vertical-align: middle;
  }
}

#egu-logo-text {
  padding: 10px 5px;
  float: right;
  line-height: 16px;
  font-size: .85em;

  a {
    padding-left: 0;
    font-weight: bold;
  }
}

#egu-logo {
  line-height: 52px;
  float: right;
  margin: 0 1em;
}

#auth {
  line-height: 52px;
  float: right;
  padding: 0 1em;
  background-color: #00335a;
}


.big {
  font-size: 48px;
  line-height: 48px;
  vertical-align: middle;
}


// footer
#footer {
  background-color: #000;
  bottom: 0;
  color: #7b7b7b;
  height: 34px;
  left: 0;
  line-height: 34px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  opacity: .9;

  a {
    color: #7b7b7b;

    &.active,
    &:hover {
      color: #49acff;
    }
  }

  .icon-egu-logo {
    font-size: 1.5em;
  }
}


// breadcrumb
#breadcrumb {
  padding: 5px 4px;
  margin: 0 0 1em;

  a {
    color: #777;
    text-shadow: 0 1px 0 #fff;

    &:hover {
      color: #333;
    }
  }


  & > li {
    display: inline-block;
    text-shadow: 0 1px 0 #fff;

    & > .divider {
      color: #aaa;
      padding: 0 5px;
    }
  }
}


#orderbyform {
  float: right;
  line-height: 30px;
  margin: 0;
}

ul {
  padding-left: 2em;
  margin-left: 0;
}


// horizontal list
.hl {
  ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    width: auto;
  }

  li {
    display: inline;
    list-style-type: none;
    margin: 0 1.2em 0 0;
    padding: 0;
    white-space: nowrap;
  }

  i {
    margin-right: 4px;
  }
}

.nav-tabs {
  a {
    color: #565656;
  }
}

article {
  background-color: #fff;
  padding: 1em 2em;
  color: #565656;

  a {
    color: #49acff;

    &:hover,
    &:focus {
      color: #565656;
    }
  }

  .help-block {
    a {
      color: #49acff;

      &:hover,
      &:focus {
        color: #565656;
      }
    }
  }
}

[class ^= 'icon-'] {
  vertical-align: middle;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}


// profile
#profile {
  background-color: #fff;
  color: #565656;
  padding: 1em 0;

  h1 {
    padding: 0;
    margin: 0;
  }

  + #gallery {
    margin-top: 1em;
  }

  .avatar {
    width: 100px;
    height: 100px;
  }
}


// gallery
#gallery {
  font-size: 1em;
  line-height: 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  clear: both;

  .flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  figure {
    display: inline-block;
    position: relative;
    padding: 0;
    width: 280px;
    height: 280px;
    margin: 4px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
      margin: 0;
      height: 280px;
      width: 280px;

      &:hover {
        box-shadow: 0 1px 3px rgb(0, 0, 0);
      }
    }
  }

  .video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 50px;
    line-height: 280px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    color: #fff;
    text-align: center;

    &:hover {
      color: #49acff;
      box-shadow: 0 1px 3px rgb(0, 0, 0);
    }
  }

  .theme {
    position: absolute;
    top: 237px;
    right: 10px;
    font-size: 30px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    color: #fff;
    text-align: center;
  }

  figcaption {
    position: absolute;
    bottom: 1.5em;
    left: 0;
    max-width: 280px;

    span {
      color: #fff;
      opacity: .8;
      text-align: left;
      width: auto;
      background-color: #242424;
      padding: 5px;
      display: block;
      line-height: 20px;
      overflow: hidden;
    }

    small {
      color: #fff;
      text-align: left;
      width: auto;
      opacity: .9;
      background-color: #49acff;
      padding: 5px;
      display: inline-block;
      line-height: 16px;
    }
  }
}

.htmx-indicator {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

#spinner {
  clear: both;
  text-align: center;
  background-color: transparent;
  line-height: 3em;
  color: #777;
  width: 100%;

  a {
    color: #777;
    text-shadow: 0 1px 0 #fff;

    &:hover {
      color: #333;
    }
  }
}

// Large desktop
@media (min-width: 1200px) {
  #main {
    width: 1152px;
  }

  .modal-dialog {
    min-width: 1102px;
  }
}


// gallery

// 980 - 1199px
@media (min-width: 980px) and (max-width: 1199px) {
  #main {
    width: 864px;
  }

  .modal-dialog {
    min-width: 814px;
  }
}


// 768 - 979px
@media (min-width: 768px) and (max-width: 979px) {
  #main {
    width: 714px;
  }

  #gallery {
    figcaption {
      font-size: .8em;
      max-width: 230px;
    }

    figure {
      width: 230px;
      height: 230px;

      img {
        width: 230px;
        height: 230px;
      }
    }

    .video {
      font-size: 25px;
      line-height: 230px;
    }
  }

  .modal-dialog {
    min-width: 700px;
  }
}


// 641 - 767px
@media (min-width: 640px) and (max-width: 767px) {
  header {
    padding: 0;
  }

  #main {
    width: 100%;
    padding-bottom: 0;
  }

  #breadcrumb {
    margin: 1em;
  }

  #orderbyform {
    display: none;
  }

  #gallery {
    width: 592px;
    margin: 0 auto 1em;

    figcaption {
      font-size: .8em;
      max-width: 140px;
    }

    figure {
      width: 140px;
      height: 140px;

      img {
        width: 140px;
        height: 140px;
      }
    }

    .video {
      font-size: 25px;
      line-height: 140px;
    }
  }
}

// 480 - 639px
@media (min-width: 480px) and (max-width: 639px) {
  header {
    padding: 0;
  }

  #main {
    width: 100%;
    padding-bottom: 0;
  }

  #breadcrumb {
    margin: 1em;
  }

  #orderbyform {
    display: none;
  }

  #gallery {
    width: 444px;
    margin: 0 auto 1em;

    figcaption {
      font-size: .8em;
      max-width: 140px;
    }

    figure {
      width: 140px;
      height: 140px;

      img {
        width: 140px;
        height: 140px;
      }
    }

    .video {
      font-size: 25px;
      line-height: 140px;
    }
  }
}


// 320 - 479px
@media (max-width: 479px) {
  header {
    padding: 0;
  }

  #main {
    width: 100%;
    padding-bottom: 0;
  }

  #breadcrumb {
    margin: 1em;
  }

  #orderbyform {
    display: none;
  }

  .modal-dialog {
    padding: 0;
  }

  #gallery {
    width: 296px;
    margin: 0 auto 1em;

    figcaption {
      font-size: .8em;
      max-width: 140px;
    }

    figure {
      width: 140px;
      height: 140px;

      img {
        width: 140px;
        height: 140px;
      }
    }

    .video {
      font-size: 25px;
      line-height: 140px;
    }
  }
}

// style fixes for small displays and hide minimap + coordiantes on map
@media (max-width: 979px) {
  .leaflet-control-minimap {
    display: none;
  }

  .leaflet-control-coordinates {
    display: none;
  }
}

#djDebugToolbarHandle {
  top: 100px !important;
}


// typeahead fix
.twitter-typeahead {
  width: auto !important;

  .tm-input {
    background-color: transparent !important;
  }
}

.media > .pull-left {
  margin-right: 1.25em;
  padding-right: 0;
}

.tabbable > ul > li > a {
  border: 1px dotted #eee;
}

.hyphens {
  hyphens: auto;

  a,
  p {
    hyphens: auto;
  }
}

.input-xs {
  height: 23px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}


// next and previous image arrows
#nextimg,
#previousimg {
  bottom: 0;
  height: auto;
  position: absolute;
  z-index: 1000;
  line-height: 34px;
  color: #7b7b7b;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
  text-align: center;

  &:hover {
    color: #49acff;
  }

  i {
    vertical-align: middle;
    line-height: 34px;
  }
}

#nextimg {
  right: .5em;
}

#previousimg {
  right: 1.5em;
}
