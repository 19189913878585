// tag fonts and colors
// sass-lint:disable no-color-literals

.tagcloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 100%;

  a {
    white-space: nowrap;
    --size: 4;
    --color: #565656;
    color: var(--color);
    opacity: calc((15 - (9 - var(--size))) / 15);
    font-size: calc(var(--size) * .25rem + .75rem);
    display: block;
    padding: .125rem .5rem;
    position: relative;
    text-decoration: none;
    letter-spacing: -.025em;

    &:hover {
      color: #49acff;
    }

    &:focus {
      outline: 1px dashed;
    }

    &[data-weight='1'] { --size: 1; }
    &[data-weight='2'] { --size: 2; }
    &[data-weight='3'] { --size: 3; }
    &[data-weight='4'] { --size: 4; }
    &[data-weight='5'] { --size: 5; }
    &[data-weight='6'] { --size: 6; }
    &[data-weight='7'] { --size: 7; }
    &[data-weight='8'] { --size: 8; }
    &[data-weight='9'] { --size: 9; }
    &[data-weight='10'] { --size: 10; }
    &[data-weight='11'] { --size: 11; }
    &[data-weight='12'] { --size: 12; }
    &[data-weight='13'] { --size: 13; }
    &[data-weight='14'] { --size: 14; }
    &[data-weight='15'] { --size: 15; }
    &[data-weight='16'] { --size: 16; }
  }
}

.colorcloud {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  a {
    display: inline-block;
    white-space: nowrap;
    margin: .5em;
    color: #565656;
    text-align: center;

    &:hover {
      white-space: nowrap;
      color: #49acff;
    }
  }
}

#colors {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  vertical-align: middle;
  padding-top: 2px;
}
