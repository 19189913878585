// forms
// sass-lint:disable no-color-literals

form {
  margin: 0;

  legend {
    margin-bottom: 10px;
  }

  .required {
    &::after {
      content: '*';
      display: inline;
      padding-left: 5px;
    }
  }

  #id_name,
  #id_fullname,
  #id_email,
  #id_default_license {
    max-width: 40em;
  }

  #formfield_id_message2 {
    display: none;
  }

  #id_last_name,
  #id_first_name,
  #id_username,
  #id_city,
  #id_country,
  #id_login,
  #id_password,
  #id_new_password1,
  #id_new_password2,
  #id_password1,
  #id_password2,
  #id_oldpassword {
    max-width: 25em;
  }

  #id_month,
  #id_day {
    min-width: 5em;
  }

  .form-table {
    margin: 0 2em;

    table {
      margin-bottom: .5em;
    }

    th {
      &:first-child {
        width: 1em;
      }
    }
  }
}


button {
  .btn {
    margin-bottom: .25em;
  }
}

fieldset {
  &:not(:first-child) {
    margin-top: 2em;
  }
}

.form-inline {
  display: inline;
}
