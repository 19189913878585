// meta
// sass-lint:disable no-color-literals

#page-meta {
  #mimetype {
    display: block;
    width: 30em;

    a {
      display: inline-block;
      border: 1px solid #888;
      border-radius: 4px;
    }
  }

  #image {
    float: right;
  }

  p,
  pre,
  table {
    margin-bottom: 20px;
  }

  th {
    text-align: left;
    padding-right: 1em;
  }
}
