// contest overview
// sass-lint:disable no-color-literals

#page-contest {
  figure {
    margin: 4px;
    padding: 0;
    width: inherit;

    img {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  #gallery {
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) {
    #gallery {
      figure {
        width: 264px;
        height: 264px;

        img {
          width: 264px;
          height: 264px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    #gallery {
      figure {
        width: 140px;
        height: 140px;

        img {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}
