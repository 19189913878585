html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
  font-size: 10pt;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f0efeb;
  min-width: 320px;
  min-height: 320px;
}

section + section {
  margin-top: 2em;
}

input,
button,
select,
textarea {
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
}

select,
option,
textarea {
  max-width: 100%;
}

input[type=text] {
  max-width: 100%;
}

small {
  font-size: 0.8em;
}
small .help-block {
  color: #737373 !important;
}

.alert ul {
  margin-bottom: 0;
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
}

h2 {
  font-size: 1.6em;
  font-weight: normal;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
}

h3 {
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: normal;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
}

h4 {
  font-size: 1em;
  line-height: normal;
  font-weight: bold;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
  margin-bottom: 5px;
}

h5 {
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
}

a {
  text-decoration: none;
}
a:link, a:hover, a:active, a:target, a:visited {
  text-decoration: none;
}
a .fancybox > img {
  margin-bottom: 0.25em;
}

cite {
  font-weight: bold;
}

#wrapper {
  min-height: 100%;
  position: relative;
}

#main {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-bottom: 47px;
}

header {
  padding: 1em;
}

.inactive {
  color: #aaa;
}
.inactive:hover {
  color: #888;
}

.hidden {
  display: none;
}

.salute {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

#header {
  background-color: #004580;
  height: 54px;
  color: #5abcff;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #ffde00;
}
#header a {
  color: #5abcff;
}
#header a:hover, #header a.active {
  color: #fff;
}
#header i {
  font-size: 16px;
}
#header .icon-egu-logo {
  color: #ffde00;
}

#menu {
  line-height: 52px;
  width: 52px;
  text-align: center;
  float: right;
  height: 52px;
}
#menu a {
  line-height: 52px;
  width: 52px;
  text-align: center;
  display: inline-block;
  height: 52px;
  cursor: pointer;
}
#menu i {
  margin-bottom: 2px;
  font-size: 22px;
  vertical-align: middle;
}

#collapse {
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
  font-size: 14px;
  background-color: #fff;
  z-index: 1;
}
#collapse .nav {
  margin: 0;
  width: 49%;
  display: inline-block;
  overflow: hidden;
  padding: 0.5em 0 0;
}
#collapse .nav li {
  padding: 0 0.5em;
}
#collapse .nav > .active > a {
  background-color: #e5e5e5;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125) inset;
  color: #555;
  text-decoration: none;
}
#collapse .nav > .active > a:hover, #collapse .nav > .active > a:focus {
  background-color: #e5e5e5;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125) inset;
  color: #555;
  text-decoration: none;
}
#collapse p {
  margin: 0;
  padding: 1em 0;
  color: #777;
  font-weight: normal;
  text-align: center;
  border-top: 1px solid #f2f2f2;
  border-bottom: 2px solid #ffde00;
}
#collapse p i {
  padding: 0 6px;
  color: #777;
  font-size: 22px;
  vertical-align: middle;
  margin-bottom: 2px;
}

#logo {
  float: left;
  line-height: 52px;
  margin: 0 1em;
}

#nav {
  float: left;
  padding-left: 2em;
}
#nav li {
  line-height: 52px;
  vertical-align: middle;
}

#egu-logo-text {
  padding: 10px 5px;
  float: right;
  line-height: 16px;
  font-size: 0.85em;
}
#egu-logo-text a {
  padding-left: 0;
  font-weight: bold;
}

#egu-logo {
  line-height: 52px;
  float: right;
  margin: 0 1em;
}

#auth {
  line-height: 52px;
  float: right;
  padding: 0 1em;
  background-color: #00335a;
}

.big {
  font-size: 48px;
  line-height: 48px;
  vertical-align: middle;
}

#footer {
  background-color: #000;
  bottom: 0;
  color: #7b7b7b;
  height: 34px;
  left: 0;
  line-height: 34px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  opacity: 0.9;
}
#footer a {
  color: #7b7b7b;
}
#footer a.active, #footer a:hover {
  color: #49acff;
}
#footer .icon-egu-logo {
  font-size: 1.5em;
}

#breadcrumb {
  padding: 5px 4px;
  margin: 0 0 1em;
}
#breadcrumb a {
  color: #777;
  text-shadow: 0 1px 0 #fff;
}
#breadcrumb a:hover {
  color: #333;
}
#breadcrumb > li {
  display: inline-block;
  text-shadow: 0 1px 0 #fff;
}
#breadcrumb > li > .divider {
  color: #aaa;
  padding: 0 5px;
}

#orderbyform {
  float: right;
  line-height: 30px;
  margin: 0;
}

ul {
  padding-left: 2em;
  margin-left: 0;
}

.hl ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  width: auto;
}
.hl li {
  display: inline;
  list-style-type: none;
  margin: 0 1.2em 0 0;
  padding: 0;
  white-space: nowrap;
}
.hl i {
  margin-right: 4px;
}

.nav-tabs a {
  color: #565656;
}

article {
  background-color: #fff;
  padding: 1em 2em;
  color: #565656;
}
article a {
  color: #49acff;
}
article a:hover, article a:focus {
  color: #565656;
}
article .help-block a {
  color: #49acff;
}
article .help-block a:hover, article .help-block a:focus {
  color: #565656;
}

[class^=icon-] {
  vertical-align: middle;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

#profile {
  background-color: #fff;
  color: #565656;
  padding: 1em 0;
}
#profile h1 {
  padding: 0;
  margin: 0;
}
#profile + #gallery {
  margin-top: 1em;
}
#profile .avatar {
  width: 100px;
  height: 100px;
}

#gallery {
  font-size: 1em;
  line-height: 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}
#gallery .flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#gallery figure {
  display: inline-block;
  position: relative;
  padding: 0;
  width: 280px;
  height: 280px;
  margin: 4px;
}
#gallery figure img {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  margin: 0;
  height: 280px;
  width: 280px;
}
#gallery figure img:hover {
  box-shadow: 0 1px 3px rgb(0, 0, 0);
}
#gallery .video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 50px;
  line-height: 280px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
}
#gallery .video:hover {
  color: #49acff;
  box-shadow: 0 1px 3px rgb(0, 0, 0);
}
#gallery .theme {
  position: absolute;
  top: 237px;
  right: 10px;
  font-size: 30px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
}
#gallery figcaption {
  position: absolute;
  bottom: 1.5em;
  left: 0;
  max-width: 280px;
}
#gallery figcaption span {
  color: #fff;
  opacity: 0.8;
  text-align: left;
  width: auto;
  background-color: #242424;
  padding: 5px;
  display: block;
  line-height: 20px;
  overflow: hidden;
}
#gallery figcaption small {
  color: #fff;
  text-align: left;
  width: auto;
  opacity: 0.9;
  background-color: #49acff;
  padding: 5px;
  display: inline-block;
  line-height: 16px;
}

.htmx-indicator {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

#spinner {
  clear: both;
  text-align: center;
  background-color: transparent;
  line-height: 3em;
  color: #777;
  width: 100%;
}
#spinner a {
  color: #777;
  text-shadow: 0 1px 0 #fff;
}
#spinner a:hover {
  color: #333;
}

@media (min-width: 1200px) {
  #main {
    width: 1152px;
  }
  .modal-dialog {
    min-width: 1102px;
  }
}
@media (min-width: 980px) and (max-width: 1199px) {
  #main {
    width: 864px;
  }
  .modal-dialog {
    min-width: 814px;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  #main {
    width: 714px;
  }
  #gallery figcaption {
    font-size: 0.8em;
    max-width: 230px;
  }
  #gallery figure {
    width: 230px;
    height: 230px;
  }
  #gallery figure img {
    width: 230px;
    height: 230px;
  }
  #gallery .video {
    font-size: 25px;
    line-height: 230px;
  }
  .modal-dialog {
    min-width: 700px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  header {
    padding: 0;
  }
  #main {
    width: 100%;
    padding-bottom: 0;
  }
  #breadcrumb {
    margin: 1em;
  }
  #orderbyform {
    display: none;
  }
  #gallery {
    width: 592px;
    margin: 0 auto 1em;
  }
  #gallery figcaption {
    font-size: 0.8em;
    max-width: 140px;
  }
  #gallery figure {
    width: 140px;
    height: 140px;
  }
  #gallery figure img {
    width: 140px;
    height: 140px;
  }
  #gallery .video {
    font-size: 25px;
    line-height: 140px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  header {
    padding: 0;
  }
  #main {
    width: 100%;
    padding-bottom: 0;
  }
  #breadcrumb {
    margin: 1em;
  }
  #orderbyform {
    display: none;
  }
  #gallery {
    width: 444px;
    margin: 0 auto 1em;
  }
  #gallery figcaption {
    font-size: 0.8em;
    max-width: 140px;
  }
  #gallery figure {
    width: 140px;
    height: 140px;
  }
  #gallery figure img {
    width: 140px;
    height: 140px;
  }
  #gallery .video {
    font-size: 25px;
    line-height: 140px;
  }
}
@media (max-width: 479px) {
  header {
    padding: 0;
  }
  #main {
    width: 100%;
    padding-bottom: 0;
  }
  #breadcrumb {
    margin: 1em;
  }
  #orderbyform {
    display: none;
  }
  .modal-dialog {
    padding: 0;
  }
  #gallery {
    width: 296px;
    margin: 0 auto 1em;
  }
  #gallery figcaption {
    font-size: 0.8em;
    max-width: 140px;
  }
  #gallery figure {
    width: 140px;
    height: 140px;
  }
  #gallery figure img {
    width: 140px;
    height: 140px;
  }
  #gallery .video {
    font-size: 25px;
    line-height: 140px;
  }
}
@media (max-width: 979px) {
  .leaflet-control-minimap {
    display: none;
  }
  .leaflet-control-coordinates {
    display: none;
  }
}
#djDebugToolbarHandle {
  top: 100px !important;
}

.twitter-typeahead {
  width: auto !important;
}
.twitter-typeahead .tm-input {
  background-color: transparent !important;
}

.media > .pull-left {
  margin-right: 1.25em;
  padding-right: 0;
}

.tabbable > ul > li > a {
  border: 1px dotted #eee;
}

.hyphens {
  hyphens: auto;
}
.hyphens a,
.hyphens p {
  hyphens: auto;
}

.input-xs {
  height: 23px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

#nextimg,
#previousimg {
  bottom: 0;
  height: auto;
  position: absolute;
  z-index: 1000;
  line-height: 34px;
  color: #7b7b7b;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
  text-align: center;
}
#nextimg:hover,
#previousimg:hover {
  color: #49acff;
}
#nextimg i,
#previousimg i {
  vertical-align: middle;
  line-height: 34px;
}

#nextimg {
  right: 0.5em;
}

#previousimg {
  right: 1.5em;
}

form {
  margin: 0;
}
form legend {
  margin-bottom: 10px;
}
form .required::after {
  content: "*";
  display: inline;
  padding-left: 5px;
}
form #id_name,
form #id_fullname,
form #id_email,
form #id_default_license {
  max-width: 40em;
}
form #formfield_id_message2 {
  display: none;
}
form #id_last_name,
form #id_first_name,
form #id_username,
form #id_city,
form #id_country,
form #id_login,
form #id_password,
form #id_new_password1,
form #id_new_password2,
form #id_password1,
form #id_password2,
form #id_oldpassword {
  max-width: 25em;
}
form #id_month,
form #id_day {
  min-width: 5em;
}
form .form-table {
  margin: 0 2em;
}
form .form-table table {
  margin-bottom: 0.5em;
}
form .form-table th:first-child {
  width: 1em;
}

button .btn {
  margin-bottom: 0.25em;
}

fieldset:not(:first-child) {
  margin-top: 2em;
}

.form-inline {
  display: inline;
}

#socialaccount {
  margin: 2em 1em;
}
#socialaccount a.socialaccount_provider {
  color: #565656;
  margin-right: 0.25em;
}
#socialaccount a.socialaccount_provider:hover {
  color: #49acff;
}

#search {
  background-color: #00335a;
  color: #fff;
  line-height: 52px;
  width: 52px;
  text-align: center;
  float: left;
}
#search a {
  line-height: 52px;
  width: 52px;
  text-align: center;
  height: 52px;
  display: inline-block;
}
#search i {
  margin-bottom: 2px;
  font-size: 22px;
  vertical-align: middle;
}

#searchsection {
  margin-bottom: 2em;
}
#searchsection #searchwrapper {
  position: relative;
  background-color: #fff;
  height: 52px;
}
#searchsection #query {
  padding: 0 0 0 10px;
  border: 0;
  font-size: 1.8em;
  margin: 0;
  height: 52px;
  line-height: 52px;
  color: #565656;
  width: 100%;
  box-shadow: 0;
}
#searchsection #searchsubmit {
  width: 52px;
  height: 52px;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  background-color: #004580;
  color: #5abcff;
  vertical-align: middle;
}
#searchsection #searchsubmit:hover {
  color: #fff;
}
#searchsection #searchsubmit i {
  font-size: 22px;
}
#searchsection select {
  border: 0;
  color: #565656;
  margin: 5px 5px 0 0;
  padding: 5px;
}
#searchsection #mediatype {
  width: 15em;
}

@media (max-width: 767px) {
  #searchsection {
    padding: 0 1em;
  }
}
.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.tagcloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.75rem;
  width: 100%;
}
.tagcloud a {
  white-space: nowrap;
  --size: 4;
  --color: #565656;
  color: var(--color);
  opacity: calc((15 - (9 - var(--size))) / 15);
  font-size: calc(var(--size) * 0.25rem + 0.75rem);
  display: block;
  padding: 0.125rem 0.5rem;
  position: relative;
  text-decoration: none;
  letter-spacing: -0.025em;
}
.tagcloud a:hover {
  color: #49acff;
}
.tagcloud a:focus {
  outline: 1px dashed;
}
.tagcloud a[data-weight="1"] {
  --size: 1;
}
.tagcloud a[data-weight="2"] {
  --size: 2;
}
.tagcloud a[data-weight="3"] {
  --size: 3;
}
.tagcloud a[data-weight="4"] {
  --size: 4;
}
.tagcloud a[data-weight="5"] {
  --size: 5;
}
.tagcloud a[data-weight="6"] {
  --size: 6;
}
.tagcloud a[data-weight="7"] {
  --size: 7;
}
.tagcloud a[data-weight="8"] {
  --size: 8;
}
.tagcloud a[data-weight="9"] {
  --size: 9;
}
.tagcloud a[data-weight="10"] {
  --size: 10;
}
.tagcloud a[data-weight="11"] {
  --size: 11;
}
.tagcloud a[data-weight="12"] {
  --size: 12;
}
.tagcloud a[data-weight="13"] {
  --size: 13;
}
.tagcloud a[data-weight="14"] {
  --size: 14;
}
.tagcloud a[data-weight="15"] {
  --size: 15;
}
.tagcloud a[data-weight="16"] {
  --size: 16;
}

.colorcloud {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.colorcloud a {
  display: inline-block;
  white-space: nowrap;
  margin: 0.5em;
  color: #565656;
  text-align: center;
}
.colorcloud a:hover {
  white-space: nowrap;
  color: #49acff;
}

#colors {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  vertical-align: middle;
  padding-top: 2px;
}

#page-meta #mimetype {
  display: block;
  width: 30em;
}
#page-meta #mimetype a {
  display: inline-block;
  border: 1px solid #888;
  border-radius: 4px;
}
#page-meta #image {
  float: right;
}
#page-meta p,
#page-meta pre,
#page-meta table {
  margin-bottom: 20px;
}
#page-meta th {
  text-align: left;
  padding-right: 1em;
}

#page-contest figure {
  margin: 4px;
  padding: 0;
  width: inherit;
}
#page-contest figure img {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
#page-contest #gallery {
  margin-left: 0;
  width: 100%;
}
@media (min-width: 768px) {
  #page-contest #gallery figure {
    width: 264px;
    height: 264px;
  }
  #page-contest #gallery figure img {
    width: 264px;
    height: 264px;
  }
}
@media (max-width: 767px) {
  #page-contest #gallery figure {
    width: 140px;
    height: 140px;
  }
  #page-contest #gallery figure img {
    width: 140px;
    height: 140px;
  }
}

#page-contest-form #gallery label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  opacity: 0.4;
}
#page-contest-form #gallery label:hover {
  opacity: 1 !important;
}
#page-contest-form #gallery [type=checkbox] {
  display: none;
}
#page-contest-form #gallery [type=checkbox]:checked + label {
  background-color: #357ebd;
  opacity: 1 !important;
}