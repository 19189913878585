// search
// sass-lint:disable no-color-literals

#search {
  background-color: #00335a;
  color: #fff;
  line-height: 52px;
  width: 52px;
  text-align: center;
  float: left;

  a {
    line-height: 52px;
    width: 52px;
    text-align: center;
    height: 52px;
    display: inline-block;
  }

  i {
    margin-bottom: 2px;
    font-size: 22px;
    vertical-align: middle;
  }
}


#searchsection {
  margin-bottom: 2em;

  #searchwrapper {
    position: relative;
    background-color: #fff;
    height: 52px;
  }

  #query {
    padding: 0 0 0 10px;
    border: 0;
    font-size: 1.8em;
    margin: 0;
    height: 52px;
    line-height: 52px;
    color: #565656;
    width: 100%;
    box-shadow: 0;
  }

  #searchsubmit {
    width: 52px;
    height: 52px;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background-color: #004580;
    color: #5abcff;
    vertical-align: middle;

    &:hover {
      color: #fff;
    }

    i {
      font-size: 22px;
    }
  }

  select {
    border: 0;
    color: #565656;
    margin: 5px 5px 0 0;
    padding: 5px;
  }

  #mediatype {
    width: 15em;
  }
}


@media (max-width: 767px) {
  #searchsection {
    padding: 0 1em;
  }
}
