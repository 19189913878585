// social accounts
// sass-lint:disable no-color-literals class-name-format

#socialaccount {
  margin: 2em 1em;

  a {
    &.socialaccount_provider {
      color: #565656;
      margin-right: .25em;

      &:hover {
        color: #49acff;
      }
    }
  }
}
