// contest form
// sass-lint:disable no-color-literals no-important

#page-contest-form {
  #gallery {
    label {
      display: inline-block;
      cursor: pointer;
      font-weight: normal;
      opacity: .4;

      &:hover {
        opacity: 1 !important;
      }
    }

    [type='checkbox'] {
      display: none;

      &:checked + label {
        background-color: #357ebd;
        opacity: 1 !important;
      }
    }
  }
}
